html {
  background-color: #343434;
  font-size: calc(15px + 0.390625vw);
  overflow-x: hidden;
}

html,
body {
  max-width: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;

}

ul li {
  color: black;
}

ul a:hover {
  text-decoration: underline;
  color: #F9F9F9;
  cursor: pointer;
}

ul a {
  font-family: "Deserta";
  top: 30px;
  position: absolute;
}

@font-face {
  font-family: 'Deserta';
  src: local('Deserta'), url(./includes/fonts/Deserta.otf) format('opentype');
}

@font-face {
  font-family: 'Inter';
  src: local('Inter'), url(./includes/fonts/Inter-Regular.ttf) format('truetype');
}

.hero-section {
  position: relative;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-size: cover;
  background-position: center;
}

.title-style {
  color: #ffffff;
  text-shadow: 0px 0px 8px rgba(0, 0, 0, 0.8);
  font-size: 2.5rem;
  font-weight: bold;
}

.scroll-icon {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 2rem;
  color: white;
  cursor: pointer;
  animation: bounce 2s infinite;
}

.overlay-style {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}

#nav-logo {
  max-height: auto;
  position: absolute;
  top: 1px;
  left: 5vw;
}